import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';
import { selectActiveVessels, selectArchivedVessels, selectCountActive, selectCountArchived, selectLastArchived, selectLastRestored, selectLoadingActive, selectLoadingArchived } from '../../store/selectors/vessels-overview.selectors';
import { VesselsOverviewActions } from '../../store/actions/vessels-overview.actions';
import { ToastrService } from 'ngx-toastr';
import { selecterror } from '../../../quantify/store/selectors/project.selectors';
import { filter, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss']
})
export class CardItemComponent implements OnInit {

  error$:Observable<any>;
  lastArchivedVessel$:Observable<any>;
  lastRestoredVessel$:Observable<any>;
  loading$:Observable<any>;
  count$:Observable<any>;

  @Input() activeTab:any=null;
  @Input() vesselsList:any=null;

  isLoading:boolean=false;
  selectedTab: string;
  showArchiveModal: boolean = false;
  selectedVessel: any = null;

  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
    private toasterService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.error$ = vesselsOverviewStore.select(selecterror);
    this.lastArchivedVessel$ = vesselsOverviewStore.select(selectLastArchived);
    this.lastRestoredVessel$ = vesselsOverviewStore.select(selectLastRestored);

    if(this.activeTab=='active'){
      this.loading$ = vesselsOverviewStore.select(selectLoadingActive);
      this.count$ = vesselsOverviewStore.select(selectCountActive);
    }else{
      this.loading$ = vesselsOverviewStore.select(selectLoadingArchived);
      this.count$ = vesselsOverviewStore.select(selectCountArchived);
    }
    this.loading$.subscribe(ans=>{
      this.isLoading=ans;
    })
  }

  ngOnInit(): void {

    this.vesselsOverviewStore.select('vesselsOverview', 'selectedTab').subscribe(tab => {
        this.selectedTab = tab;
      });
    
  }

  navigateToEmissionNavigator(vessel){
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.currentVessel({vesselId:vessel.id}));
    this.router.navigate([vessel.id+'/emission-navigator'], { relativeTo: this.route });
  }

  restoreVessel(vessel){
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.restoreVessel({vessel:vessel}));
    this.checkRestored();
  }

  archiveVessel(vessel) {
    this.selectedVessel = vessel;
    this.vesselsOverviewStore.dispatch(
      VesselsOverviewActions.archiveVessel({ vessel: vessel })
    );
    this.checkArchived();
    this.showArchiveModal = false;
    this.selectedVessel = null;
  }

  openArchiveModal(vessel) {
    this.showArchiveModal = true;
    this.selectedVessel = vessel;
  }

  closeArchiveModal() {
    this.showArchiveModal = false;
    this.selectedVessel = null;
  }

  checkArchived(){
    this.error$.pipe(
      filter(error => !!error), 
        take(1)
    ).subscribe(error => {
      
      this.toasterService.error('Archiving failed');
      });
    this.lastArchivedVessel$.pipe(
      filter(lastArchivedVessel => !!lastArchivedVessel), 
        take(1)
    ).subscribe(result => {
        this.toasterService.success('Vessel archived successfully');
      });
  }

  checkRestored(){
    this.error$.pipe(
      filter(error => !!error), 
        take(1)
    ).subscribe(error => {
      
      this.toasterService.error('Restoring failed');
      });
    this.lastRestoredVessel$.pipe(
      filter(lastRestoredVessel => !!lastRestoredVessel), 
        take(1)
    ).subscribe(result => {
        this.toasterService.success('Vessel restored successfully');
      });
  }

  toggleDrawer(){
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleVesselDrawer({canShow:true}))
  }
}
