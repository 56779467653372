<ng-container *ngIf="isLoading==true">
  <div class="loading">
    <div class="spinner-border text-secondary"></div>
    <label for="">Loading data..</label>
  </div>
</ng-container>
<ng-container *ngIf="isLoading==false">
    <div class="empty-state" *ngIf="(count$|async)==0">
        
    <div class="empty-state-block">
      
            <div class="empty-state-description-block" [ngStyle]="{'margin-top': selectedTab === 'archived' ? '-112px' : '-2%'}">
        <div class="loop-button">
                    <img src="../../../assets/danish-shipping-assets/black-search-icon.svg" alt="">
        </div>
        <div class="empty-state-txt-block">
          <label class="empty-state-title">
                    {{ selectedTab === 'active' ? 'No active vessels' : 'No archived vessels' }}
          </label>
          <p class="empty-state-description">
                    {{ selectedTab === 'active' 
                ? 'No vessels are currently active. Click "Add Vessel" to create one.'
                      : 'No vessels are currently archived.' }}
          </p>
        </div>
              <div class="empty-state-add-option" *ngIf="selectedTab === 'active'" (click)="toggleDrawer()">
                <img src="../../../assets/danish-shipping-assets/plus-icon.svg" alt="">
          <label for="">Add vessel</label>
        </div>
      </div>
    </div>

  </div>
    <div class="vessel-card" *ngIf="(count$|async)>0">
    <div class="card-container" *ngFor="let vessel of vesselsList">
            <img src="../../../../../assets/danish-shipping-assets/ship.png" alt="Ship Image" class="vessel-image">

      <div class="card-body">
        <div class="head-title-block">
          <div class="title-content">
                        <label class="head-title">{{vessel.vesselName}}</label>
          </div>

          <div
            class="archive-block"
            (click)="openArchiveModal(vessel)"
            *ngIf="activeTab == 'active'"
          >
            <img src="../../../../../assets/danish-shipping-assets/archive-box-icon.svg" alt="Ship Image" class=" ">
            <label for="">Archive</label>
          </div>
        </div>
        <div class="details-block mt-2">
          <label class="details-txt">IMO: </label>
                    <label class="details-sub-txt"> {{vessel.imo}}</label>
        </div>
        <div class="details-block">
          <label class="details-txt">Lifetime: </label>
                    <label class="details-sub-txt"> {{vessel.lifeTime}} years</label>
        </div>
      </div>

            <div class="lifecycle-screening-card-content" *ngIf="activeTab=='active'">
        
        <div class="lifecycle-head-block">
          <div class="lifecycle-title-block">
                        <label class="lifecycle-title">Lifecycle Screening: {{vessel.totalEmission}} </label>
            <label class="lifecycle-value"> tCO2eq</label>
          </div>
          <div class="lifecycle-icon">
                        <img src="../../../../../assets/danish-shipping-assets/right-arrow.svg" alt="Ship Image" class=" ">
          </div>
        </div>

                <div class="bar-block" *ngIf="vessel.totalEmission==0">
          <div class="bar-item scope1"></div>
          <div class="bar-item scope2"></div>
          <div class="bar-item scope3"></div>
        </div>
                <div class="bar-block" *ngIf="vessel.totalEmission!=0">
                    <div class="bar-item scope1-light" [ngStyle]="{'width':vessel.scope1Percentage+'%'}"></div>
                    <div class="bar-item scope2-light" [ngStyle]="{'width':vessel.scope2Percentage+'%'}"></div>
                    <div class="bar-item scope3-light" [ngStyle]="{'width':vessel.scope3Percentage+'%'}"></div>
        </div>
        <div class="scope-data-container">
          <div class="scope-data">
            <div class="scope-title-bloc">
                            <div class="scope-square" [ngClass]="{'scope1':vessel.totalEmission==0,'scope1-light':vessel.totalEmission!=0}"></div>
              <label for="">Scope 1</label>
            </div>
            <div class="scope-value">
                            <label class="lifecycle-title">{{vessel.scope1Emission}}</label>
              <label class="lifecycle-value">tCO2eq</label>
            </div>
          </div>
          <div class="scope-data">
            <div class="scope-title-bloc">
                            <div class="scope-square" [ngClass]="{'scope2':vessel.totalEmission==0,'scope2-light':vessel.totalEmission!=0}"></div>
              <label for="">Scope 2</label>
            </div>
            <div class="scope-value">
                            <label class="lifecycle-title">{{vessel.scope2Emission}}</label>
              <label class="lifecycle-value">tCO2eq</label>
            </div>
          </div>
          <div class="scope-data">
            <div class="scope-title-bloc">
                            <div class="scope-square" [ngClass]="{'scope3':vessel.totalEmission==0,'scope3-light':vessel.totalEmission!=0}"></div>
              <label for="">Scope 3</label>
            </div>
            <div class="scope-value">
                            <label class="lifecycle-title">{{vessel.scope3Emission}}</label>
              <label class="lifecycle-value">tCO2eq</label>
            </div>
          </div>
        </div>
        
      </div>
            <div class="lifecycle-screening-card-content"  *ngIf="activeTab=='active'">
                <div class="calculate-btn" (click)="navigateToEmissionNavigator(vessel)">
                    <img src="../../../../../assets/danish-shipping-assets/plus-icon.svg" alt="" class="">
          <label for="">Calculate</label>
        </div>
        
      </div>
            <div class="restore-btn" (click)="restoreVessel(vessel)" *ngIf="activeTab!='active'">
                <img src="../../../../../assets/danish-shipping-assets/restore.svg" alt="" class="">
        <label for="">Restore</label>
      </div>
    </div>
  </div>
</ng-container>

<div id="openModal-about" class="modalDialog" *ngIf="showArchiveModal">
  <div class="re-modal-content">
    <div class="header-container">
      <p class="Warning-title">Confirm archiving vessel</p>
      <div class="modal-cnt">
        <p class="above-text">
          You are about to archive a vessel for your company. This action will
          move the selected vessel from the active list to the archive. You can
          view and restore archived vessels at any time from the archive
          section.
        </p>
      </div>
      <div class="modal-btn-container">
        <button class="btn-solid default" (click)="closeArchiveModal()">
          Cancel
        </button>
        <button class="btn-solid secondary" (click)="archiveVessel(selectedVessel)">
          Archive
        </button>
      </div>
    </div>
  </div>
</div>
