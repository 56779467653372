<div class="split left">
    <label class="p-body" (click)="exit()"> Back to overview</label>
    
    <label (click)="attemptChangeState(1,'screening-setup')" class="h3-heading-upper"
    [ngClass]="{
        'current-state':currentState === 'screening-setup',
        'can-be-selected':manageMenu( 'screening-setup')&&currentState !== 'screening-setup'
        }"
    > Project Setup</label>
    
    <label (click)="attemptChangeState(2,'system-definition')" class="h3-heading-upper"
    [ngClass]="{
        'current-state':currentState === 'system-definition',
        'can-be-selected':manageMenu('system-definition') && currentState !== 'system-definition'
        }"> System definition</label>
    <label (click)="attemptChangeState(3,'screening-inventory')" class="h3-heading-upper"
    [ngClass]="{
        'current-state':currentState === 'screening-inventory',
        'can-be-selected':manageMenu('screening-inventory') && currentState !== 'screening-inventory'
        }"> Product inventory</label>
    <label (click)="attemptChangeState(4,'review')"  class="h3-heading-upper"
    [ngClass]="{
        'current-state':currentState === 'review',
        'can-be-selected':manageMenu('review') && currentState !== 'review'
        }"> project review</label>
    <label (click)="attemptChangeState(5,'result-summary')"  class="h3-heading-upper" [ngClass]="{
            'current-state':currentState === 'result-summary',
            'can-be-selected':hasResult==true && currentState !== 'result-summary'
            }"> Project summary</label>
    
    <label (click)="showLog()"  class="h3-heading-upper btn-log"> Project history</label>
</div>
<div class="split left history" *ngIf="log">
    <h2 class="title-h2">
        {{currentScreening?.name}} History
    </h2>
    <div class="row">
        <ul class="timeline">
          <li *ngFor="let log of screeningLogs">
            <label class="timeline-title">{{log.description}}</label>
            <label class="timeline-date"><em class="icon-user"></em> {{log.userName}}</label>
            <label class="timeline-date">{{log.date | date:'medium'}}</label>
          </li>
        </ul>
      </div>
</div>
<div class="split right">
    <app-screening-setup   *ngIf="currentState=='screening-setup'" [screeningId]="currentScreening?.id" (close)="closeScreeningSetup($event)" ></app-screening-setup>
    <app-system-definition *ngIf="currentState=='system-definition'" (close)="closeSystemDefinition($event)"></app-system-definition>
    <app-screening-inventory *ngIf="currentState=='screening-inventory'" (close)="closeScreeningInventory($event)" ></app-screening-inventory>
    <app-screening-review *ngIf="currentState=='review'" (close)="closeScreeningReview($event)" ></app-screening-review>
    <app-result-summary *ngIf="currentState=='result-summary'"></app-result-summary>
</div>
<!-- <app-customer-contact></app-customer-contact> -->