import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClipboardModule } from "@angular/cdk/clipboard";


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminSpaceComponent } from "./admin-space/admin-space.component";
import { SideMenuComponent } from "./admin-space/side-menu/side-menu.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { CompaniesOverviewComponent } from "./admin-space/companies-overview/companies-overview.component";
import { ShowCompanyComponent } from "./admin-space/show-company/show-company.component";
import { AddCompanyComponent } from "./admin-space/companies-overview/add-company/add-company.component";
import { CompanyDetailComponent } from "./admin-space/show-company/company-detail/company-detail.component";
import { DetailUserComponent } from "./admin-space/show-company/detail-user/detail-user.component";
import { AddUserComponent } from "./admin-space/show-company/add-user/add-user.component";
import { ExternalCompaniesOverviewComponent } from "./admin-space/external-companies-overview/external-companies-overview.component";
import { EditExternalComponent } from "./admin-space/external-companies-overview/edit-external/edit-external.component";
import { DetailsExternalComponent } from "./admin-space/external-companies-overview/details-external/details-external.component";
import { EditCompanyComponent } from "./admin-space/show-company/edit-company/edit-company.component";
import { ResetPasswordComponent } from "./admin-space/reset-password/reset-password.component";
import { ProfileImageComponent } from "./admin-space/profile-image/profile-image.component";
import { ServiceProviderSpaceComponent } from "./service-provider-space/service-provider-space.component";
import { VesselOperatorSpaceComponent } from "./vessel-operator-space/vessel-operator-space.component";
import { FilterPipePipe } from "./pipes/filter-pipe.pipe";
import { SortPipe } from "./pipes/sort.pipe";
import { SpSideMenuComponent } from "./service-provider-space/sp-side-menu/sp-side-menu.component";
import { ComponentOverviewComponent } from "./service-provider-space/component-overview/component-overview.component";
import { ComponentEditViewComponent } from "./service-provider-space/component-overview/component-edit-view/component-edit-view.component";
import { AddFileComponent } from "./service-provider-space/component-overview/component-edit-view/add-file/add-file.component";
import { WarehouseOverviewComponent } from "./service-provider-space/warehouse-overview/warehouse-overview.component";
import { EditWarehouseComponent } from "./service-provider-space/warehouse-overview/edit-warehouse/edit-warehouse.component";
import { UpdateStatusComponent } from "./service-provider-space/component-overview/update-status/update-status.component";
import { ComponentDetailsComponent } from "./service-provider-space/component-overview/component-details/component-details.component";
import { ComponentEditCoreComponent } from "./service-provider-space/component-overview/component-details/component-edit-core/component-edit-core.component";
import { ComponentLogComponent } from "./service-provider-space/component-overview/component-details/component-log/component-log.component";
import { ComponentFileLogComponent } from "./service-provider-space/component-overview/component-details/component-file-log/component-file-log.component";
import { ToastrModule } from "ngx-toastr";
import { VesselOperatorOverviewComponent } from "./vessel-operator-space/vessel-operator-overview/vessel-operator-overview.component";
import { VoSideMenuComponent } from "./vessel-operator-space/vo-side-menu/vo-side-menu.component";
import { VesselsOverviewComponent } from "./vessel-operator-space/vessels-overview/vessels-overview.component";
import { EditVesselComponent } from "./vessel-operator-space/vessels-overview/edit-vessel/edit-vessel.component";
import { ShowFileComponent } from "./show-file/show-file.component";
import { ShowVesselComponent } from "./vessel-operator-space/show-vessel/show-vessel.component";
import { EditLocationComponent } from "./vessel-operator-space/show-vessel/edit-location/edit-location.component";
import { ShowLocationComponent } from "./vessel-operator-space/show-vessel/show-location/show-location.component";
import { UnassignedComponentsOverviewComponent } from "./vessel-operator-space/unassigned-components-overview/unassigned-components-overview.component";
import { EditUnassignedComponentComponent } from "./vessel-operator-space/unassigned-components-overview/edit-unassigned-component/edit-unassigned-component.component";
import { LocationDetailsComponent } from "./vessel-operator-space/show-vessel/show-location/location-details/location-details.component";
import { VoUpdateStatusComponent } from "./vessel-operator-space/show-vessel/show-location/vo-update-status/vo-update-status.component";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { PrintPdfComponent } from "./print-pdf/print-pdf.component";
import { ExchangePanelComponent } from "./service-provider-space/exchange-panel/exchange-panel.component";
import { ComponentPictureComponent } from "./service-provider-space/component-overview/component-details/component-picture/component-picture.component";
import { NgxPaginationModule } from "ngx-pagination";
import { SpComponentFilterComponent } from "./service-provider-space/component-overview/sp-component-filter/sp-component-filter.component";
import { RequestDemoComponent } from "./request-demo/request-demo.component";
import { VoNotifiationComponent } from "./vessel-operator-space/vo-notifiation/vo-notifiation.component";
import { Co2LightComponent } from "./service-provider-space/co2-light/co2-light.component";
import { LightCalculatorComponent } from "./service-provider-space/co2-light/light-calculator/light-calculator.component";
import { LightResultComponent } from "./service-provider-space/co2-light/light-result/light-result.component";
import { VesselSectionComponent } from "./service-provider-space/co2-light/light-calculator/vessel-section/vessel-section.component";
import { ComponentSectionComponent } from "./service-provider-space/co2-light/light-calculator/component-section/component-section.component";
import { ScenarioSectionComponent } from "./service-provider-space/co2-light/light-calculator/scenario-section/scenario-section.component";
import { LightErrorComponent } from "./service-provider-space/co2-light/light-error/light-error.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { MobileOverviewComponent } from "./mobile-overview/mobile-overview.component";
import { ScreeningOverviewComponent } from "./service-provider-space/screening-overview/screening-overview.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CompareProjectsComponent } from "./service-provider-space/compare-projects/compare-projects.component";

import { LogoutModalComponent } from "./shared/logout-modal/logout-modal.component";
import { ScreeningModalComponent } from "./service-provider-space/screening-overview/screening-modal/screening-modal.component";
import { PlatformUpdatesComponent } from "./admin-space/platform-updates/platform-updates.component";
import { EditPlatformUpdateComponent } from "./admin-space/platform-updates/edit-platform-update/edit-platform-update.component";
import { HomeAdminComponent } from "./service-provider-space/home-admin/home-admin.component";
import { CustomerContactComponent } from "./service-provider-space/customer-contact/customer-contact.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { CompareModalComponent } from "./service-provider-space/screening-overview/compare-modal/compare-modal.component";
import { CompareFunctionComponent } from "./service-provider-space/screening-overview/compare-function/compare-function.component";
import { FinalStepComponent } from "./login/final-step/final-step.component";

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { SuccessSignupComponent } from "./login/success-signup/success-signup.component";
import { OneTimeRedirectionComponent } from "./service-provider-space/screening-overview/one-time-redirection/one-time-redirection.component";
import { SignupFormComponent } from "./login/signup-form/signup-form.component";
import { DashboardComponent } from "./admin-space/dashboard/dashboard.component";
import { AcademyAdminComponent } from "./admin-space/academy-admin/academy-admin.component";
import { ReflowAcademyComponent } from "./service-provider-space/reflow-academy/reflow-academy.component";
import { VideosOverviewComponent } from "./service-provider-space/reflow-academy/videos-overview/videos-overview.component";
import { VideoPlayComponent } from "./service-provider-space/reflow-academy/video-play/video-play.component";
import { PlayListComponent } from "./service-provider-space/reflow-academy/play-list/play-list.component";
import { VideoCardComponent } from "./service-provider-space/reflow-academy/video-card/video-card.component";
import { MasterSheetComponent } from "./admin-space/master-sheet/master-sheet.component";
import { DatabaseOverviewComponent } from "./admin-space/master-sheet/database-overview/database-overview.component";
import { EditDatabaseComponent } from "./admin-space/master-sheet/database-overview/edit-database/edit-database.component";
import { GroupsOverviewComponent } from "./admin-space/master-sheet/groups-overview/groups-overview.component";
import { EditGroupComponent } from "./admin-space/master-sheet/groups-overview/edit-group/edit-group.component";
import { SubGroupsOverviewComponent } from "./admin-space/master-sheet/sub-groups-overview/sub-groups-overview.component";
import { EditSubGroupComponent } from "./admin-space/master-sheet/sub-groups-overview/edit-sub-group/edit-sub-group.component";
import { EndGroupsOverviewComponent } from "./admin-space/master-sheet/end-groups-overview/end-groups-overview.component";
import { EditEndGroupComponent } from "./admin-space/master-sheet/end-groups-overview/edit-end-group/edit-end-group.component";
import { ProcessesOverviewComponent } from "./admin-space/master-sheet/processes-overview/processes-overview.component";
import { EditProcessComponent } from "./admin-space/master-sheet/processes-overview/edit-process/edit-process.component";
import { LocationsOverviewComponent } from "./admin-space/master-sheet/locations-overview/locations-overview.component";
import { UpsertLocationComponent } from "./admin-space/master-sheet/locations-overview/upsert-location/upsert-location.component";
import { EditFolderModalComponent } from "./service-provider-space/screening-overview/edit-folder-modal/edit-folder-modal.component";
import { NewLoginComponent } from "./new-login/new-login.component";
import { SalesSpaceComponent } from "./sales-space/sales-space.component";
import { SalesOverviewComponent } from "./sales-space/sales-overview/sales-overview.component";
import { SlSideMenuComponent } from "./sales-space/sl-side-menu/side-menu.component";
import { ValidationDashboardComponent } from "./admin-space/validation-dashboard/validation-dashboard.component";
import { ValidationReviewComponent } from "./admin-space/validation-dashboard/validation-review/validation-review.component";
import { ValidationSearchFilterPipe } from "./pipes/validation-search-filter.pipe";
import { ShareOverviewComponent } from "./service-provider-space/share-overview/share-overview.component";
import { ProjectComponent } from "./service-provider-space/share-overview/project/project.component";
import { MyProjectComponent } from "./service-provider-space/share-overview/my-project/my-project.component";
import { SharedWithMeComponent } from "./service-provider-space/share-overview/shared-with-me/shared-with-me.component";
import { EngineersSpaceComponent } from "./engineers-space/engineers-space.component";
import { EngineersSideMenuComponent } from "./engineers-space/engineers-side-menu/engineers-side-menu.component";
import { ShareModalComponent } from "./service-provider-space/share-overview/share-modal/share-modal.component";
import { ProjectOverviewComponent } from "./service-provider-space/share-overview/project-overview/project-overview.component";
import { MultipleProjectsComponent } from "./service-provider-space/share-overview/multiple-projects/multiple-projects.component";
import { SharedNewFolderComponent } from "./service-provider-space/share-overview/shared-new-folder/shared-new-folder.component";
import { ProjectDetailsShareComponent } from "./service-provider-space/share-overview/project/project-details-share/project-details-share.component";
import { SharedProjectDetailsComponent } from "./service-provider-space/share-overview/project-overview/shared-project-details/shared-project-details.component";
import { SharedWithCompaniesComponent } from "./service-provider-space/share-overview/project/shared-with-companies/shared-with-companies.component";
import { ImportProjectComponent } from "./service-provider-space/share-overview/import-project/import-project.component";
import { UpdateCreditComponent } from "./admin-space/companies-overview/update-credit/update-credit.component";
import { SubscriptionModalComponent } from "./admin-space/companies-overview/subscription-modal/subscription-modal.component";
import { TransferModalComponent } from "./admin-space/companies-overview/transfer-modal/transfer-modal.component";
import { PublicAssessmentComponent } from "./service-provider-space/share-overview/public-assessment/public-assessment.component";
import { MoveProjectComponent } from "./service-provider-space/screening-overview/move-project/move-project.component";
import { AdjustResourcesComponent } from "./admin-space/companies-overview/adjust-resources/adjust-resources.component";
import { RequestDataComponent } from "./service-provider-space/share-overview/request-data/request-data.component";
import { RequestProjectOverviewComponent } from "./service-provider-space/share-overview/request-project-overview/request-project-overview.component";
import { RequestModalComponent } from "./service-provider-space/share-overview/request-modal/request-modal.component";
import { CompanyAssesstmentOverviewComponent } from "./service-provider-space/company-assesstment-overview/company-assesstment-overview.component";
import { AssessmentDashboardComponent } from "./service-provider-space/company-assesstment-overview/assessment-dashboard/assessment-dashboard.component";
import { CalculationStepperComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/calculation-stepper.component";
import { YearSelectionComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/year-selection/year-selection.component";
import { CompanyProfileComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/company-profile/company-profile.component";
import { DataCollectionComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/data-collection/data-collection.component";
import { DcProcessModalComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/data-collection/dc-process-modal/dc-process-modal.component";
import { CompanyAssessmentDataComponent } from "./admin-space/company-assessment-data/company-assessment-data.component";
import { DatabasesComponent } from "./admin-space/company-assessment-data/databases/databases.component";
import { CompanyAssessmentGroupsComponent } from "./admin-space/company-assessment-data/company-assessment-groups/company-assessment-groups.component";
import { CompanyAssessmentSubGroupsComponent } from "./admin-space/company-assessment-data/company-assessment-sub-groups/company-assessment-sub-groups.component";
import { CompanyAssessmentEndGroupsComponent } from "./admin-space/company-assessment-data/company-assessment-end-groups/company-assessment-end-groups.component";
import { CompanyAssessmentProcessComponent } from "./admin-space/company-assessment-data/company-assessment-process/company-assessment-process.component";
import { EditCompanyAssessmentGroupComponent } from "./admin-space/company-assessment-data/company-assessment-groups/edit-company-assessment-group/edit-company-assessment-group.component";
import { ResultCompanyAsssessmentComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/result-company-asssessment/result-company-asssessment.component";
import { WarningModalComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/data-collection/warning-modal/warning-modal.component";

import { ProjectPreviewComponent } from "./service-provider-space/compare-projects/project-preview/project-preview.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { CompanyLoadingComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/company-loading/company-loading.component";
import { JsonParserPipe } from "./pipes/json-parser.pipe";

import { NgxIndexedDBModule, DBConfig } from "ngx-indexed-db";
import { YardscoreAdminComponent } from "./yardscore-admin/yardscore-admin.component";
import { YardscoreShipyardComponent } from "./yardscore-shipyard/yardscore-shipyard.component";
import { YSACustomerManagementComponent } from "./yardscore-admin/ysa-customer-management/ysa-customer-management.component";
import { YSAOverviewComponent } from "./yardscore-admin/ysa-overview/ysa-overview.component";
import { YSAUpsertCompanyComponent } from "./yardscore-admin/ysa-customer-management/ysa-upsert-company/ysa-upsert-company.component";
import { YsaDeleteCompanyComponent } from "./yardscore-admin/ysa-customer-management/ysa-delete-company/ysa-delete-company.component";
import { YsaUsersManagementComponent } from "./yardscore-admin/ysa-users-management/ysa-users-management.component";
import { YsaUpsertUserComponent } from "./yardscore-admin/ysa-users-management/ysa-upsert-user/ysa-upsert-user.component";
import { YsaDeleteUserComponent } from "./yardscore-admin/ysa-users-management/ysa-delete-user/ysa-delete-user.component";
import { QuestionnaireManagementComponent } from "./yardscore-admin/questionnaire-management/questionnaire-management.component";
import { QuestionnaireOverviewComponent } from "./yardscore-admin/questionnaire-management/questionnaire-overview/questionnaire-overview.component";
import { QuestionnaireCategoriesComponent } from "./yardscore-admin/questionnaire-management/questionnaire-categories/questionnaire-categories.component";
import { QuestionnaireModulesComponent } from "./yardscore-admin/questionnaire-management/questionnaire-modules/questionnaire-modules.component";
import { QuestionnaireSubcategoriesComponent } from "./yardscore-admin/questionnaire-management/questionnaire-subcategories/questionnaire-subcategories.component";
import { QuestionnaireChildUpsertComponent } from "./yardscore-admin/questionnaire-management/questionnaire-child-upsert/questionnaire-child-upsert.component";
import { QuestionnaireChildDeleteComponent } from "./yardscore-admin/questionnaire-management/questionnaire-child-delete/questionnaire-child-delete.component";
import { QuestionnaireComponent } from "./yardscore-admin/questionnaire-management/questionnaire/questionnaire.component";
import { ReviewQuestionnairesComponent } from "./yardscore-admin/review-questionnaires/review-questionnaires.component";
import { ReviewQuestionnairesOverviewComponent } from "./yardscore-admin/review-questionnaires/review-questionnaires-overview/review-questionnaires-overview.component";
import { RqPassQuestionnaireComponent } from "./yardscore-admin/review-questionnaires/review-questionnaires-overview/rq-pass-questionnaire/rq-pass-questionnaire.component";
import { RqViewQuestionnaireComponent } from "./yardscore-admin/review-questionnaires/review-questionnaires-overview/rq-view-questionnaire/rq-view-questionnaire.component";
import { YssDashboardComponent } from "./yardscore-shipyard/yss-dashboard/yss-dashboard.component";
import { YssQuestionnaireComponent } from "./yardscore-shipyard/yss-questionnaire/yss-questionnaire.component";
import { YssQuestionnaireOverviewComponent } from "./yardscore-shipyard/yss-questionnaire/yss-questionnaire-overview/yss-questionnaire-overview.component";
import { YssQuestionnaireManagementComponent } from "./yardscore-shipyard/yss-questionnaire/yss-questionnaire-management/yss-questionnaire-management.component";
import { YssQuestionnaireArchiveComponent } from "./yardscore-shipyard/yss-questionnaire-archive/yss-questionnaire-archive.component";
import { SubmitQuestionnaireComponent } from "./yardscore-shipyard/yss-questionnaire/yss-questionnaire-overview/submit-questionnaire/submit-questionnaire.component";
import { VersionHistoryComponent } from "./yardscore-shipyard/yss-questionnaire/yss-questionnaire-overview/version-history/version-history.component";
import { QuimaUsageComponent } from "./admin-space/quima-usage/quima-usage.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard } from "./auth/auth.guard";
import { SortbyPipe } from './pipes/yardscore-pipes/sortby.pipe';
import { AuthModule } from "@auth0/auth0-angular";
import { RedirectionComponent } from './new-login/redirection/redirection.component';
import { AuthInterceptor } from "./auth/auth.interceptor";
import { ErrorPageComponent } from './new-login/error-page/error-page.component';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceProviderSpaceModule } from "./service-provider-space/service-provider-space.module";

const dbConfig: DBConfig = {
  name: "reflow-db",
  version: 3,
  objectStoresMeta: [
    {
      store: "Screenings",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "name", keypath: "name", options: { unique: false } },
      ],
    },
    {
      store: "Folders",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "name", keypath: "name", options: { unique: true } },
      ],
    },
  ],
};


import { FolderEffects } from "./service-provider-space/quantify/store/effects/folder.effects";
import { ProjectEffects } from "./service-provider-space/quantify/store/effects/project.effects";
import { FolderReducer } from "./service-provider-space/quantify/store/reducers/folder.reducer";
import { ProjectReducer } from "./service-provider-space/quantify/store/reducers/project.reducer";
import { UserReducer } from "./store/reducers/user.reducers";
import { SharedModule } from "./shared/shared.module";
import { VesselsOverviewReducer } from "./service-provider-space/danish-shipping/store/reducers/vessels-overview.reducers";
import { VesselsOverviewEffects } from "./service-provider-space/danish-shipping/store/effects/vessels-overview.effects";
import { ScenariosReducer } from "./service-provider-space/danish-shipping/store/reducers/scenarios.reducers";
import { ScenariosEffects } from "./service-provider-space/danish-shipping/store/effects/scenarios.effects";
import { sessionStorageMetaReducer } from "./store/reducers/storage.metareducer";

export const metaReducers: MetaReducer<any>[] = [sessionStorageMetaReducer];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFormComponent,
    AdminSpaceComponent,
    SideMenuComponent,
    TermsConditionComponent,
    CompaniesOverviewComponent,
    ShowCompanyComponent,
    AddCompanyComponent,
    CompanyDetailComponent,
    DetailUserComponent,
    AddUserComponent,
    ExternalCompaniesOverviewComponent,
    EditExternalComponent,
    DetailsExternalComponent,
    EditCompanyComponent,
    ResetPasswordComponent,
    ProfileImageComponent,
    ServiceProviderSpaceComponent,
    VesselOperatorSpaceComponent,
    SortPipe,
    SpSideMenuComponent,
    ComponentOverviewComponent,
    ComponentEditViewComponent,
    AddFileComponent,
    WarehouseOverviewComponent,
    EditWarehouseComponent,
    UpdateStatusComponent,
    ComponentDetailsComponent,
    ComponentEditCoreComponent,
    ComponentLogComponent,
    ComponentFileLogComponent,
    ShowFileComponent,
    VesselOperatorOverviewComponent,
    VoSideMenuComponent,
    VesselsOverviewComponent,
    EditVesselComponent,
    ShowVesselComponent,
    EditLocationComponent,
    ShowLocationComponent,
    UnassignedComponentsOverviewComponent,
    EditUnassignedComponentComponent,
    LocationDetailsComponent,
    VoUpdateStatusComponent,
    PrintPdfComponent,
    ComponentPictureComponent,
    ExchangePanelComponent,
    SpComponentFilterComponent,
    RequestDemoComponent,
    VoNotifiationComponent,
    Co2LightComponent,
    LightCalculatorComponent,
    LightResultComponent,
    VesselSectionComponent,
    ComponentSectionComponent,
    ScenarioSectionComponent,
    LightErrorComponent,
    MobileOverviewComponent,
    ScreeningOverviewComponent,
    LogoutModalComponent,
    ScreeningModalComponent,
    PlatformUpdatesComponent,
    EditPlatformUpdateComponent,
    HomeAdminComponent,
    CustomerContactComponent,
    CompareModalComponent,
    CompareFunctionComponent,
    FinalStepComponent,
    SuccessSignupComponent,
    OneTimeRedirectionComponent,
    SignupFormComponent,
    DashboardComponent,
    CompareProjectsComponent,
    AcademyAdminComponent,
    ReflowAcademyComponent,
    VideosOverviewComponent,
    VideoPlayComponent,
    PlayListComponent,
    VideoCardComponent,
    MasterSheetComponent,
    DatabaseOverviewComponent,
    EditDatabaseComponent,
    GroupsOverviewComponent,
    EditGroupComponent,
    SubGroupsOverviewComponent,
    EditSubGroupComponent,
    EndGroupsOverviewComponent,
    EditEndGroupComponent,
    ProcessesOverviewComponent,
    EditProcessComponent,
    LocationsOverviewComponent,
    UpsertLocationComponent,
    EditFolderModalComponent,
    NewLoginComponent,
    SalesSpaceComponent,
    SalesOverviewComponent,
    SlSideMenuComponent,
    ValidationDashboardComponent,
    ValidationReviewComponent,
    ValidationSearchFilterPipe,
    ShareOverviewComponent,
    ProjectComponent,
    MyProjectComponent,
    SharedWithMeComponent,
    EngineersSpaceComponent,
    EngineersSideMenuComponent,
    ShareModalComponent,
    ProjectOverviewComponent,
    MultipleProjectsComponent,
    SharedNewFolderComponent,
    ProjectDetailsShareComponent,
    SharedProjectDetailsComponent,
    SharedWithCompaniesComponent,
    ImportProjectComponent,
    UpdateCreditComponent,
    SubscriptionModalComponent,
    TransferModalComponent,
    PublicAssessmentComponent,
    MoveProjectComponent,
    AdjustResourcesComponent,
    RequestDataComponent,
    RequestProjectOverviewComponent,
    RequestModalComponent,
    CompanyAssesstmentOverviewComponent,
    AssessmentDashboardComponent,
    CalculationStepperComponent,
    YearSelectionComponent,
    CompanyProfileComponent,
    DataCollectionComponent,
    DcProcessModalComponent,
    CompanyAssessmentDataComponent,
    DatabasesComponent,
    CompanyAssessmentGroupsComponent,
    CompanyAssessmentSubGroupsComponent,
    CompanyAssessmentEndGroupsComponent,
    CompanyAssessmentProcessComponent,
    EditCompanyAssessmentGroupComponent,
    ResultCompanyAsssessmentComponent,
    WarningModalComponent,
    RequestModalComponent,
    ProjectPreviewComponent,
    CompanyLoadingComponent,
    JsonParserPipe,
    YardscoreAdminComponent,
    YardscoreShipyardComponent,
    YSACustomerManagementComponent,
    YSAOverviewComponent,
    YSAUpsertCompanyComponent,
    YsaDeleteCompanyComponent,
    YsaUsersManagementComponent,
    YsaUpsertUserComponent,
    YsaDeleteUserComponent,
    QuestionnaireManagementComponent,
    QuestionnaireOverviewComponent,
    QuestionnaireCategoriesComponent,
    QuestionnaireModulesComponent,
    QuestionnaireSubcategoriesComponent,
    QuestionnaireChildUpsertComponent,
    QuestionnaireChildDeleteComponent,
    QuestionnaireComponent,
    ReviewQuestionnairesComponent,
    ReviewQuestionnairesOverviewComponent,
    RqPassQuestionnaireComponent,
    RqViewQuestionnaireComponent,
    YssDashboardComponent,
    YssQuestionnaireComponent,
    YssQuestionnaireOverviewComponent,
    YssQuestionnaireManagementComponent,
    YssQuestionnaireArchiveComponent,
    SubmitQuestionnaireComponent,
    VersionHistoryComponent,
    JsonParserPipe,
    QuimaUsageComponent,
    SortbyPipe,
    RedirectionComponent,
    ErrorPageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    NgxCaptchaModule,
    NgbModule,
    
    ClipboardModule,
    NgxQRCodeModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgxIndexedDBModule.forRoot(dbConfig),

    
    AuthModule.forRoot({
      domain: 'reflowplatform.eu.auth0.com',
      clientId: 'LPsvvzTYARds7m8NA1ZkgoXrChAyKEiR',
      redirectUri: window.location.origin+'/redirection',
      audience: 'https://localhost:5001',
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        audience: 'https://localhost:5001',
      },
    }),
    
    StoreModule.forRoot(
      {
        "folders": FolderReducer,
        "projects": ProjectReducer,
        "user": UserReducer,
        "vesselsOverview": VesselsOverviewReducer,
        "scenarios":ScenariosReducer
      },
      { metaReducers:[sessionStorageMetaReducer] }
    ),
    
    EffectsModule.forRoot([FolderEffects,ProjectEffects,VesselsOverviewEffects,ScenariosEffects]),
    
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ServiceProviderSpaceModule,
    SharedModule
  ],

  providers: [
    DatePipe,
    CurrencyPipe,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  //entryComponents: [MaterialModalComponent],
})
export class AppModule {}
