<app-material-modal *ngIf="showModal$ | async" [scenario]="currentScenario" [vesselScenario]="recyclingMethodData?.id" [existingData]="optionToEdit" [parentId]="parentId"></app-material-modal>


<div  class="cards-section" *ngIf="scenarioInventories$|async as scenarioInventories">
    <!-- Content for Tab 3 -->

    <div class="empty-state-block" *ngIf="scenarioInventories.length==0">
            
      <div class="empty-state-description-block">
        <div class="empty-state-txt-block">
          <label class="empty-state-title">To continue please add Recycling Method and Country</label>
          <p class="empty-state-description">
            Choose the recycling method and the country where the recycling will take place. These selections will influence the lifecycle emissions calculations, as different methods and locations have varying climate impacts.
          </p>
        </div>
        <div class="empty-state-add-option" (click)="addOption('Recycling Method and Country')">
          <img src="../../../assets/danish-shipping-assets/plus-icon.svg" alt="">
          <label for="">Add Option</label>
        </div>
      </div>
    </div>

    <div class="options-table-container" *ngIf="scenarioInventories.length!=0">
      <table class="options-table">
        <thead>
          <tr>
            <th class="option-header">Method</th>
            <th class="weight-header">Country</th>
            <th class="action-header"></th>
          </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let option of scenarioInventories; let i = index;">
                <tr>
                  <td>{{option.optionTitle}}</td>
                  <td class="add-weight">
                    <span class="unit">{{option.country}}</span>
                  </td>
                  <td class="actions">
                      <span class="delete-link" (click)="deleteOption(option.id)">Delete</span>
                      <span class="edit-link" (click)="edit(option,'Recycling Method and Country')">Edit</span>
                    </td>
                </tr>
            </ng-container>
        
        </tbody>
      </table>
 
    </div>
  </div>




