<app-material-modal *ngIf="showModal$ | async" [scenario]="currentScenario" [vesselScenario]="fuelConsumptionData?.id" [existingData]="optionToEdit" [parentId]="parentId"></app-material-modal>
<div class="cards-section" *ngIf="scenarioInventories$|async as scenarioInventories">
   
    <!-- Content for Tab 2 --> 
    <div class="empty-state-block" *ngIf="scenarioInventories.length==0">
     
     <div class="empty-state-description-block">
       <div class="empty-state-txt-block">
         <label class="empty-state-title">To continue add Fuel Consumption and Type</label>
         <p class="empty-state-description">
           Enter the type and amount of fuel used for the vessel, along with the amount of lubricating oil. These inputs are
           needed for accurate lifecycle screening results, as they directly influence the emissions generated during the
           vessel's operation.
         </p>
       </div>
       <div class="empty-state-add-option" >
         <img src="../../../assets/danish-shipping-assets/plus-icon.svg" alt="">
         <label for="" (click)="addOption('Fuel')">Add Option</label>
       </div>
     </div>
   </div>

   <div class="options-table-container" *ngIf="scenarioInventories.length!=0">
     <table class="options-table">
       <thead>
         <tr>
           <th class="option-header">Option</th>
           <th class="weight-header">Total weight</th>
           <th class="action-header"></th>
         </tr>
       </thead>
       <tbody>
        <ng-container *ngFor="let option of scenarioInventories; let i = index;">
          <tr>
            <td>{{option.optionTitle}}</td>
            <td class="add-weight">
              <input type="text" class="input-weight" placeholder="Add weight" [(ngModel)]="option.optionValue" (ngModelChange)="updateOption(option,'parent')">
              <div ngbDropdown>
               <div class="dropdown-wrapper no-arrow" id="select-weight" ngbDropdownToggle>
                 <span class="drop-txt">{{ option.unit!=null&&option.unit!=""?option.unit:'t' }}</span>
                 <img src="../../../../assets/danish-shipping-assets/drop-arrow-icon.svg" alt="" class="percentage-icon">
               </div>
               <div ngbDropdownMenu aria-labelledby="select-weight">
                 <div ngbDropdownItem (click)="option.unit = 't';updateOption(option,'parent')">t</div>
                 <div ngbDropdownItem (click)="option.unit = 'm';updateOption(option,'parent')">m</div>
                 <div ngbDropdownItem (click)="option.unit = 'kg';updateOption(option,'parent')">kg</div>
               </div>
             </div>
              
            </td>
            <td class="actions">
              <span class="delete-link" (click)="deleteOption(option.id)">Delete</span>
              <span class="edit-link" (click)="edit(option,'Fuel')">Edit</span>
            </td>
          </tr>
          <tr *ngIf="children[option?.id]==null">
            <td class="empty-oil" (click)="parentId=option.id;addOption('Lubricating Oil')">
              <img src="../../../assets/danish-shipping-assets/plus-blue-icon.svg" alt="" class="plus-icon">
              <label for="">&nbsp;Select lubrication oil</label>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr *ngIf="children[option?.id]!=null" >
            <td style="padding-left: 40px !important;">{{children[option?.id].optionTitle}}</td>
            <td class="add-weight">
              <input type="text" class="input-weight" placeholder="Add weight" [(ngModel)]="children[option?.id].optionValue" (ngModelChange)="updateOption(children[option?.id],'child')">
              <div ngbDropdown>
               <div class="dropdown-wrapper no-arrow" id="select-weight" ngbDropdownToggle>
                 <span class="drop-txt">{{ children[option?.id].unit!=null&&children[option?.id].unit!=""?children[option?.id].unit:'t' }}</span>
                 <img src="../../../../assets/danish-shipping-assets/drop-arrow-icon.svg" alt="" class="percentage-icon">
               </div>
               <div ngbDropdownMenu aria-labelledby="select-weight">
                 <div ngbDropdownItem (click)="children[option?.id].unit = 't';updateOption(children[option?.id],'child')">t</div>
                 <div ngbDropdownItem (click)="children[option?.id].unit = 'm';updateOption(children[option?.id],'child')">m</div>
                 <div ngbDropdownItem (click)="children[option?.id].unit = 'kg';updateOption(children[option?.id],'child')">kg</div>
               </div>
             </div>
              
            </td>
            <td class="actions">
              <span class="delete-link" (click)="deleteOption(children[option?.id].id)">Delete</span>
              <span class="edit-link" (click)="edit(children[option?.id],'Lubricating Oil')">Edit</span>
            </td>
          </tr>
        </ng-container>
         
       </tbody>
     </table>

     <div class="add-option-btn" (click)="parentId=0;addOption('Fuel')">
       <img src="../../../assets/danish-shipping-assets/plus-gray-icon.svg" alt="" class="plus-icon">
       <label class="add-option-txt">Add Option</label>
     </div>

     <span class="consumption-note">Please add whole lifetime consumption </span>
   </div>
     
</div>