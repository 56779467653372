<div class="vessel-drawer-container">
  <div class="vd-header-container">
    <div class="vd-header">
      <div class="vd-title">Add a vessel</div>
      <div class="vd-close" (click)="toggleDrawer()">
        <img
          src="../../../../../assets/danish-shipping-assets/close.svg"
          alt=""
        />
      </div>
    </div>
    <p class="vd-description">Add IMO number to import vessel information</p>
  </div>
  <form [formGroup]="vesselBuilderForm" class="vd-form-container">
    <div class="vd-input-container">
      <label for="" class="vd-lbl">IMO number</label>
      <div class="input-wrapper">
        <input
          type="text"
          id="imo-number"
          class="vd-input"
          placeholder="234567890"
          value="234567890"
          formControlName="IMO"
        />
        <label
          class="btn import-button"
          for="imo-number-file"
          (click)="importVesselData()"
        >
          <span *ngIf="showLoadingVesselData == false">Import</span>
          <div
            *ngIf="showLoadingVesselData == true"
            class="spinner-border text-secondary loading-small"
          ></div>
        </label>
      </div>
      <label *ngIf="showIMOerror == true" for="" class="error-message">{{
        IMOErrorMessage
      }}</label>
    </div>
    <div class="vd-input-container">
      <label for="" class="vd-lbl">Vessel Total Lifetime</label>
      <div class="input-wrapper">
        <input
          type="text"
          class="vd-input"
          placeholder="10"
          value="10"
          formControlName="LifeTime"
        />
        <span class="suffix">years</span>
      </div>
    </div>

    <div class="vd-img-container">
      <img class="vd-img" [src]="imageUrl" alt="" />
      <img
        class="vd-icon"
        src="../../../../../assets/danish-shipping-assets/verified-icon.svg"
        alt=""
      />
    </div>

    <div class="vd-input-container">
      <label for="" class="vd-lbl">Vessel Name</label>
      <div class="input-wrapper">
        <input
          type="text"
          class="vd-input"
          placeholder="MAERSK MC-KINNEY MOLLER"
          formControlName="VesselName"
        />
      </div>
    </div>
    <div class="vd-input-container">
      <label for="" class="vd-lbl">Vessel Type</label>
      <div ngbDropdown>
        <div
          class="dropdown-wrapper no-arrow"
          id="vesselType"
          ngbDropdownToggle
        >
          <span class="drop-txt">{{ selectedItem }}</span>
          <img
            src="../../../../../assets/danish-shipping-assets/drop-arrow-icon.svg"
            alt=""
            class="percentage-icon"
          />
        </div>
        <div ngbDropdownMenu aria-labelledby="vesselType" class="typesDropdown">
          <ng-container *ngFor="let type of vesselTypes">
            <div ngbDropdownItem (click)="selectItem(type)">{{ type }}</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="vd-input-container">
          <label for="" class="vd-lbl">DWT</label>
          <div class="input-wrapper">
            <input
              type="text"
              class="vd-input"
              placeholder="65,574"
              value="65,574"
              formControlName="VesselDWT"
            />
            <span class="suffix">t</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="vd-input-container">
          <label for="" class="vd-lbl">Length</label>
          <div class="input-wrapper">
            <input
              type="text"
              class="vd-input"
              placeholder="67"
              value="67"
              formControlName="VesselLength"
            />
            <span class="suffix">m</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vd-input-container">
      <label for="" class="vd-lbl">Beam</label>
      <div class="input-wrapper">
        <input
          type="text"
          class="vd-input"
          placeholder="67"
          value="59"
          formControlName="VesselBeam"
        />
        <span class="suffix">m</span>
      </div>
    </div>
  </form>
  <div class="vd-actions-container">
    <div class="btn cancel" (click)="toggleDrawer()">Cancel</div>
    <div class="btn save" (click)="createVessel()">
      <span *ngIf="showLoadingCreate == false">Add vessel</span>
      <div
        *ngIf="showLoadingCreate == true"
        class="spinner-border text-secondary loading-small loading-light"
      ></div>
    </div>
  </div>
</div>
