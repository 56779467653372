import { Component, OnInit } from '@angular/core';
import { props, select, Store } from '@ngrx/store';
import { FoldersActions } from '../store/actions/folder.actions';
import { Observable, pipe, Subscription } from 'rxjs';
import { FoldersState } from '../store/reducers/folder.reducer';
import {  UserState } from '../../../store/reducers/user.reducers';
import { ProjectsState } from '../store/reducers/project.reducer';
import { ProjectsActions } from '../store/actions/project.actions';
import { selectCompanyId, selectCurrentUser, selectUserId, selectUserInfo } from '../../../store/selectors/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { selectCanShowArchiveModal, selectsLastfoldertDispatched, selectsRootOfLastSelectedNode, selectSuccessMessage } from '../store/selectors/folder.selectors';
import { take } from 'rxjs/operators';
import {  selectCustomerData, selectResultProjectt, selectSelectedProjectt, selectshowImportProject, selectShowMoveProjectModal, selectShowMovingFolder, selectShowRefillModal, selectShowShareModal, selectShowValidationModal } from '../store/selectors/project.selectors';

@Component({
  selector: 'app-quantify-calculator',
  templateUrl: './quantify-calculator.component.html',
  styleUrls: ['./quantify-calculator.component.scss']
})
export class QuantifyCalculatorComponent implements OnInit {

  showUpsertFolderModal$:Observable<boolean>;
  showRefillModal$:Observable<boolean>;
  recentProjects$:Observable<any>;
  companyId$:Observable<any>;
  draftFolders$:Observable<any>;
  completedFolders$:Observable<any>;
  archivedFolders$:Observable<any>;
  lastfoldertDispatched$:Observable<any>;
  selectedProject$:Observable<any>;
  resultProject$:Observable<any>;
  rootOfLastSelectedNode$:Observable<any>;
  customerData$:Observable<any>;

  showMovingFolder$:Observable<any>;
  showMoveProjectModal$:Observable<any>;
  showShareModal$:Observable<any>;
  showValidationModal$:Observable<any>;
  showImportProject$:Observable<any>;
  showArchiveModal$:Observable<boolean>;

  user$:Observable<any>;
  currentUserData$:Observable<any>;
  
  companyId:any=null;
  successMessage:any="";
  customerData:any=null;
  subscription: Subscription;
  noDataContext = {
    draft: 'Draft',
    completed: 'Completed',
    archived: 'Archived',
  };
  
  constructor(
    private foldersStore: Store<{ folders: FoldersState }>,
    private projectsStore: Store<{ projects: ProjectsState }>,
    private userStore: Store<{ user: UserState }>,
    private toasterService: ToastrService,
  ) { 

    this.projectsStore.dispatch(ProjectsActions.initState());

    this.showUpsertFolderModal$=foldersStore.pipe(
      select(state=>state.folders.upsertFolder.canShowUpsert)
    )

    this.resultProject$=foldersStore.select(selectResultProjectt)

    this.showShareModal$=foldersStore.select(selectShowShareModal)

    this.showImportProject$=foldersStore.select(selectshowImportProject)

    this.showRefillModal$=foldersStore.select(selectShowRefillModal)
    
    this.rootOfLastSelectedNode$=foldersStore.select(selectsRootOfLastSelectedNode)

    this.showArchiveModal$=foldersStore.select(selectCanShowArchiveModal)

    this.showMovingFolder$=foldersStore.select(selectShowMovingFolder)

    this.showMoveProjectModal$=foldersStore.select(selectShowMoveProjectModal)

    this.selectedProject$=projectsStore.select(selectSelectedProjectt)

    this.showValidationModal$=projectsStore.select(selectShowValidationModal)

    this.lastfoldertDispatched$=projectsStore.pipe(
      select(state=>state.projects.lastProjectsDispatcher)
    )

    this.draftFolders$=foldersStore.pipe(
      select(state=>state.folders.draftFolders)
    )
    this.archivedFolders$=foldersStore.pipe(
      select(state=>state.folders.archivedFolders)
    )
    this.completedFolders$=foldersStore.pipe(
      select(state=>state.folders.completedFolders)
    )

    this.recentProjects$=projectsStore.pipe(
      select(state=>state.projects.recentProjects)
    )
    this.companyId$ = userStore.select(selectCompanyId);
    this.customerData$ = userStore.select(selectCustomerData);
    this.user$ = userStore.select(selectUserInfo);
    this.currentUserData$ = userStore.select(selectCurrentUser);

    this.subscription = this.foldersStore.select(selectSuccessMessage).subscribe(successMessage => {
      this.successMessage=successMessage;
    });
    
  }

  ngOnInit(): void {

    this.companyId$.subscribe(companyId => {
      
      if (companyId) {
        this.companyId=companyId;
        this.projectsStore.dispatch(ProjectsActions.loadRecentProjects({ companyId }));
        this.projectsStore.dispatch(ProjectsActions.retrieveCustomerCompany({ id:companyId }));
        this.customerData$.subscribe(ans=>{
          this.customerData=ans;
        })
        this.fetchFolders();
      } else {
        console.error('Company ID is undefined');
      }
    });
  }

  public fetchFolders(){
    ['draft','completed','archived'].forEach(element => {
      this.foldersStore.dispatch(FoldersActions.fetchFolders({getFolderDto:{
          companyId:this.companyId,
          status:element,
          loadingCount:0
        }})
      )
      this.foldersStore.dispatch(FoldersActions.setFolderLoading({key:'loading'+element, loading: true }))
    });
  }

  public toggleUpsertFolder(modalType){
    this.foldersStore.dispatch(FoldersActions.toggleUpsertFolder(
      {
        upsertFolderProps:{
          modalType:modalType,
          oldFolderId:null,
          oldFolderName:null,
          targetParentId:null,
          canShowUpsert:true,
          completed:null
        }
      }
    ))

  }

  upsertFolderResult(e){
    //this.foldersStore.dispatch(FoldersActions.toggleTrackChanges());
    if(e.success==true){
      
      this.toasterService.success(this.successMessage);
      //this.foldersStore.dispatch(FoldersActions.setAfterSuccess())
      
    }else{
      this.toasterService.error('Operation failed');
    }
    
  }

  archiveResult(e){
    //this.foldersStore.dispatch(FoldersActions.toggleTrackChanges());
    console.log(e);
    
    if(e.success==true){
      
      this.toasterService.success(this.successMessage);
      //this.foldersStore.dispatch(FoldersActions.setAfterSuccess())
      
    }else{
      this.toasterService.error('Operation failed');
    }
    
  }
  moveResult(e){
    if(e.success){
      this.toasterService.success(e.success);
    }else{
      this.toasterService.error('Operation failed');
    }
  }

  public createScreening(): void {
    this.projectsStore.dispatch(ProjectsActions.createNewProject({emptyScreeningDto:{
      companyId:this.companyId,
      folderId:0
    }}))
    
    
  }
  toggleNode(node) {
    node.expanded = !node.expanded;
  }

  // Function to add a new subfolder to a node
  addSubfolder(node) {
    node.children = node.children || [];
    node.children.push({ name: 'New Folder', children: [] });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleImportProject(){
    this.projectsStore.dispatch(ProjectsActions.toggleImportModal({canShow:true}));
  }
  public isAdmin:boolean=false;
  public showRefillModal() {
    this.currentUserData$.subscribe(ans=>{
      const user_role = ans.role;
      this.isAdmin=user_role=='Master Admin';
      
      this.projectsStore.dispatch(ProjectsActions.toggleRefillModal({canShow:true}));
    })
  }

}
