import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class VesselExportService {

  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public downloadPdf(prefix: number,id:any):Observable<any> {
    return this.http.get(
      `${this.environmentUrl}/api/vesselExport/generatePdf/${prefix}/${id}`,
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }),
      }
    ).pipe(
      tap(blob => {
        console.log(blob); // Check the raw blob
      })
    );
  }
  public DownloadFile(data: Blob, filename: string): void {
    saveAs(data, filename);
  }
}
